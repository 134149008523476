<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <div id="debugView" ref="debugViewRef">DEBUG TEXT</div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api';
import { updateVh } from '@/lib/helpers';

export default {

  setup(props, context) {
    const debugViewRef = ref(null);

    const onResize = () => {
      updateVh();
    }

    onMounted(() => {
      onResize();

      let width = window.innerWidth;
      let height = window.innerHeight;

      console.log(debugViewRef.value.innerHTML);

      debugViewRef.value.innerHTML = 'Width: '+width+', Height: '+height+'<br />';

      window.addEventListener('resize', () => {
        onResize();
      })
    })

    return {
      debugViewRef,
    }
  }
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100 - 0vh);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.view {
  position: relative;
  max-width: 600px;
}

#debugView {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 20px;
}
</style>
