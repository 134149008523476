<template>
  <div class="home view">
    <div class="hv-bg">
      
    </div>
    <div class="hv-content" v-show="!loading">
      <div class="hv-header">
        <div class="hv-header-line hv-header-voting" v-show="true" ref="headerVotingRef">
          <div class="hv-header-one">STICKY VS.<br />CLEAN</div>
          <div class="hv-header-two">WHAT SIDE<br />ARE YOU<br />ON?</div>
        </div>
        <div class="hv-header-line" v-show="!canVote" ref="headerAfterVotingRef">
          <div class=" hv-header-after-voting">
            <div class="hv-header-one">I'M ON</div>
            <div class="hv-header-two" v-if="currentState === STATES.STICKY">TEAM<br />STICKY<br />CUP</div>
            <div class="hv-header-two" v-else>TEAM<br />CLEAN<br />CUP</div>
          </div>
        </div>
      </div>
      <div class="hv-bottom-content">
        <div class="hv-bg-yellow" v-show="!loading" ref="bgYellowRef"></div>
        <div class="hv-filter-btn" v-show="!canVote" ref="filterBtnRef" @click="onFilterBtnClicked">
          <div class="hv-filter-label">TAP TO GET LENS</div>
        </div>
        <div class="hv-reese-container" ref="reeseContainerRef">
          <div class="hv-reese-cup sticky"></div>
          <div class="hv-reese-cup full"></div>
          <div class="hv-reese-cup clean"></div>
        </div>
        <div class="hv-vote-btns-container" ref="voteBtnsContainerRef">
          <div class="vote-btn-particle-container" ref="voteParticleContainerRef">
            <canvas id="voteParticleCanvas" ref="voteParticleCanvasRef"></canvas>
          </div>
          <div 
            :class="[
              'btn vote-btn vote-sticky-btn', 
              canVote ? '' : 'disabled',
              canVote ? '' : getVoteBtnSize(stickyVotePer),
            ]" 
            @click="onVoteStickyBtnClicked"
            ref="voteStickyBtnRef"
          >
            <div class="vote-btn-particle-container" ref="voteStickyBtnParticleContainerRef"></div>
            <div class="vote-btn-bg"></div>
            <div class="vote-result" v-show="!canVote">{{ stickyVotePer+'%' }}</div>
            <div class="vote-btn-label">STICKY</div>
          </div>
          <div 
            :class="[
              'btn vote-btn vote-clean-btn', 
              canVote ? '' : 'disabled',
              canVote ? '' : getVoteBtnSize(cleanVotePer),
            ]" 
            @click="onVoteCleanBtnClicked"
            ref="voteCleanBtnRef"
          >
            <div class="vote-btn-particle-container" ref="voteCleanBtnParticleContainerRef"></div>
            <div class="vote-btn-bg"></div>
            <div class="vote-result" v-show="!canVote">{{ cleanVotePer+'%' }}</div>
            <div class="vote-btn-label">CLEAN</div>
          </div>
        </div>
        <div class="hv-hashtag">#StickyVsCleanCup</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, reactive, computed, watch, watchEffect, onMounted } from '@vue/composition-api';
import gsap from 'gsap';
import Emitter from '@/lib/emitter';

import iconLikeClean from '@/assets/icon_like_clean_shadow.png';

import { useStore } from 'vuex';

export default {
  // name: 'Home',
  props: {
    id: String
  },
  
  setup(props, context) {
    const {
      $router,
      $store,
    } = context.root;

    const deeplinkId = ref('');
    const correctDeeplink = ref(false);
    // let correctDeeplink = false;
    
    const STATES = ref({
      VOTING: 'VOTING',
      STICKY: 'STICKY',
      CLEAN: 'CLEAN'
    });
    const currentState = ref(STATES.value.VOTING);
    const canVote = computed(() => {
      console.log('correctDeeplink:', correctDeeplink.value);
      let result = !correctDeeplink.value && currentState.value === STATES.value.VOTING;
      console.log('canVote:', result);

      return result;
    });
    const loading = ref(true);
    const storeVotes = computed(() => $store.state.votes);
    const cleanVotePer = computed(() => {
      let per = Math.round( (storeVotes.value.clean / (storeVotes.value.sticky + storeVotes.value.clean)) * 100 );

      // per = 83;
      return per;
    });
    const stickyVotePer = computed(() => {
      // let per = storeVotes.value.sticky / (storeVotes.value.sticky + storeVotes.value.clean);
      console.log('cleanVotePer:', cleanVotePer.value);
      let per = 100 - cleanVotePer.value;

      // per = 19;
      return per;
    });

    // Refs
    const headerVotingRef = ref(null);
    const headerAfterVotingRef = ref(null);
    const bgYellowRef = ref(null);
    const reeseContainerRef = ref(null);
    const voteStickyBtnRef = ref(null);
    const voteCleanBtnRef = ref(null);
    const filterBtnRef = ref(null);
    const voteParticleCanvasRef = ref(null);
    const voteParticleContainerRef = ref(null);
    const voteBtnsContainerRef = ref(null);
    const voteCleanBtnParticleContainerRef = ref(null);
    const voteStickyBtnParticleContainerRef = ref(null);
    
    // const votes = ref({sticky: 4, clean: 5});
    // const id = ref(null);

    console.log('props:', props.id);
    // console.log(currentState.value);
    // console.log(STATES.value.CLEAN);

    onMounted(() => {
      console.log('onMounted');

      checkForDeeplink();

      // initEmitter();

      if( correctDeeplink.value ) {

      } else {
        $store.dispatch('getVotes')
          .then(() => {
            loading.value = false;
          })
          .catch(() => {

          })
      }
    });

    watch(
      currentState,
      (newValue, prevValue) => {
        console.log('prevValue:', prevValue);
        console.log('newValue:', newValue);
      }
    )

    watch(
      correctDeeplink,
      (newValue, prevValue) => {

      }
    )

    const getVoteBtnSize = (per) => {
      if( per < 20 ) {
        return 'xsmall';
      // } else if( per < 20 ) {
      //   return 'small';
      } else if( per < 35 ) {
        return 'small';
      } else if( per < 40 ) {
        return 'medium';
      } else if( per < 45 ) {
        return 'large';
      }

      return '';
    }


    /**
     * Vote methods
     */
    const onVoteStickyBtnClicked = () => {
      if( currentState.value !== STATES.value.VOTING ) {
        return;
      }

      vote(1, true);
    }

    const onVoteCleanBtnClicked = () => {
      if( currentState.value !== STATES.value.VOTING ) {
        return;
      }

      vote(2, true);

      // $store.dispatch('addVote', {voteId: 2})
      //   .then(() => {
      //     console.log('addVote completed');
      //     currentState.value = STATES.value.CLEAN;
      //     animateToCleanState();
      //   })
    }

    const vote = (id, animate=false) => {
      $store.dispatch('addVote', {voteId: id})
        .then(() => {
          console.log('addVote completed');

          // loading.value = false;
          if( !animate ) {
            loading.value = false;
            if( id === 1 ) {
              currentState.value = STATES.value.STICKY;
              animateToStickyState(animate);

              setTimeout(() => {
                initEmitter('sticky');
              }, 100);
            } else {
              currentState.value = STATES.value.CLEAN;
              animateToCleanState(animate);

              setTimeout(() => {
                initEmitter('clean');
              }, 100);
            }
          }

          
        })
      
      if( animate ) {
        if( id === 1 ) {
          currentState.value = STATES.value.STICKY;
          animateToStickyState(animate);

          // if( animate ) {
            initEmitter('sticky');
          // }
        } else {
          currentState.value = STATES.value.CLEAN;
          animateToCleanState(animate);

          // if( animate ) {
            initEmitter('clean');
          // }
        }
      }
      
      
      // if( id === 1 ) {
      //   // let rect = voteBtnsContainerRef.value.getBoundingClientRect();

      //   // let startX = -rect.width / 2 * 0.95;
      //   // let endX = startX + stickyBtnWidth/100 * rect.width * 0.8;

      //   // console.log('startX:', startX);
      //   // console.log('endX:', endX);
        
      //   // setTimeout(() => {
      //     initEmitter('sticky');
      //   // }, 100);
      // } else {
      //   // let rect = voteBtnsContainerRef.value.getBoundingClientRect();

      //   // let startX = -rect.width/2 + (100 - cleanBtnWidth) / 100 * 1.1 * rect.width;
      //   // let endX = startX + cleanBtnWidth/100 * rect.width * 0.75;

      //   // console.log('startX:', startX);
      //   // console.log('endX:', endX);
        
      //   // setTimeout(() => {
      //     initEmitter('clean');
      //   // }, 100);
      // }

      // // Debug
      // loading.value = false;
      // if( id === 1 ) {
      //   currentState.value = STATES.value.STICKY;
      //   animateToStickyState(animate);
      // } else {
      //   currentState.value = STATES.value.CLEAN;
      //   animateToCleanState(animate);
      // }
    }

    const minVoteBtnWidth = 30;
    const maxVoteBtnWidth = 70;

    const animateToStickyState = (animate=false) => {
      let stickyBtnWidth = Math.min(Math.max(stickyVotePer.value, minVoteBtnWidth), maxVoteBtnWidth);
      // let cleanBtnWidth = Math.min(Math.max(cleanVotePer.value, minVoteBtnWidth), maxVoteBtnWidth);
      let cleanBtnWidth = 100 - stickyBtnWidth;

      animateToAfterVoteState('29%', stickyBtnWidth, cleanBtnWidth, animate, () => {
        
      });

      // let rect = voteBtnsContainerRef.value.getBoundingClientRect();

      // let startX = -rect.width / 2 * 0.95;
      // let endX = startX + stickyBtnWidth/100 * rect.width * 0.8;

      // console.log('startX:', startX);
      // console.log('endX:', endX);
      
      // // setTimeout(() => {
      //   initEmitter('sticky', startX, endX);
      // // }, 100);
      

      // if( animate ) {

      // } else {

      // }

      // gsap.set(bgYellowRef.value, {top:'0%'});
      // gsap.set(reeseContainerRef.value, {left: '29%'});
      // gsap.set(voteStickyBtnRef.value, {width:stickyBtnWidth+'%'});
      // gsap.set(voteCleanBtnRef.value, {width:cleanBtnWidth+'%'});
      
    }

    const animateToCleanState = (animate=false) => {
      let stickyBtnWidth = Math.min(Math.max(stickyVotePer.value, minVoteBtnWidth), maxVoteBtnWidth);
      // let cleanBtnWidth = Math.max(cleanVotePer.value, minVoteBtnWidth);
      let cleanBtnWidth = 100 - stickyBtnWidth;

      console.log('stickyVotePer:', stickyVotePer.value);
      console.log('cleanVotePer:', cleanVotePer.value);
      console.log('stickyBtnWidth:', stickyBtnWidth);
      console.log('cleanBtnWidth:', cleanBtnWidth);

      animateToAfterVoteState('-71%', stickyBtnWidth, cleanBtnWidth, animate);

      // let rect = voteBtnsContainerRef.value.getBoundingClientRect();

      // let startX = -rect.width/2 + (100 - cleanBtnWidth) / 100 * 1.1 * rect.width;
      // let endX = startX + cleanBtnWidth/100 * rect.width * 0.75;

      // console.log('startX:', startX);
      // console.log('endX:', endX);
      
      // // setTimeout(() => {
      //   initEmitter('clean', startX, endX);
      // // }, 100);

      // if( animate ) {
      //   let timeline = gsap.timeline();
      //   timeline.to(headerVotingRef.value, {duration: 0.3, ease: 'sine.out', top: '-=10', alpha: 0});
      //   timeline.to(bgYellowRef.value, {duration: 0.5, ease: 'sine.out', top: '0%'}, '-=0.3');
      //   timeline.to(reeseContainerRef.value, {duration: 0.5, ease: 'sine.out', left: '-71%'}, '-=0.5');
      //   timeline.fromTo(headerAfterVotingRef.value, {y: '+=10', alpha: 0}, {duration: 0.3, ease: 'sine.out', y: '0', alpha: 1}, '-=0.3');
      //   timeline.fromTo(filterBtnRef.value, {y: '+=10', alpha: 0}, {duration: 0.3, ease: 'sine.out', y: '0', alpha: 1}, '-=0.3');
      //   timeline.fromTo(voteStickyBtnRef.value, {width:'50%'}, {duration: 0.5, ease: 'sine.out', width: stickyBtnWidth+'%'}, '-=0.6');
      //   timeline.fromTo(voteCleanBtnRef.value, {width:'50%'}, {duration: 0.5, ease: 'sine.out', width: cleanBtnWidth+'%'}, '-=0.6');

      //   timeline.timeScale(1.5);
      // } else {
      //   gsap.set(bgYellowRef.value, {top:'0%'});
      //   gsap.set(reeseContainerRef.value, {left: '-71%'});
      //   gsap.set(voteStickyBtnRef.value, {width:stickyBtnWidth+'%'});
      //   gsap.set(voteCleanBtnRef.value, {width:cleanBtnWidth+'%'});
      // }

      
    }

    const animateToAfterVoteState = (reeseContainerX, stickyBtnWidth, cleanBtnWidth, animate=false, callback=null) => {
      // let stickyBtnWidth = Math.max(stickyVotePer.value, minVoteBtnWidth);
      // // let cleanBtnWidth = Math.max(cleanVotePer.value, minVoteBtnWidth);
      // let cleanBtnWidth = 100 - stickyBtnWidth;

      // console.log('stickyVotePer:', stickyVotePer.value);
      // console.log('cleanVotePer:', cleanVotePer.value);
      // console.log('stickyBtnWidth:', stickyBtnWidth);
      // console.log('cleanBtnWidth:', cleanBtnWidth);

      if( animate ) {
        let timeline = gsap.timeline();
        timeline.to(headerVotingRef.value, {duration: 0.3, ease: 'sine.out', top: '-=10', alpha: 0});
        timeline.to(bgYellowRef.value, {duration: 0.5, ease: 'sine.out', top: '0%'}, '-=0.3');
        timeline.to(reeseContainerRef.value, {duration: 0.5, ease: 'sine.out', left: reeseContainerX}, '-=0.5');
        timeline.fromTo(headerAfterVotingRef.value, {y: '+=10', alpha: 0}, {duration: 0.3, ease: 'sine.out', y: '0', alpha: 1}, '-=0.3');
        timeline.fromTo(filterBtnRef.value, {y: '+=10', alpha: 0}, {duration: 0.3, ease: 'sine.out', y: '0', alpha: 1}, '-=0.3');
        timeline.fromTo(voteStickyBtnRef.value, {width:'50%'}, {duration: 0.5, ease: 'sine.out', width: stickyBtnWidth+'%'}, '-=0.6');
        timeline.fromTo(voteCleanBtnRef.value, {width:'50%'}, {duration: 0.5, ease: 'sine.out', width: cleanBtnWidth+'%'}, '-=0.6');

        timeline.timeScale(1.25);
      } else {
        gsap.set(headerVotingRef.value, {alpha: 0});
        gsap.set(headerAfterVotingRef.value, {alpha: 1});
        gsap.set(bgYellowRef.value, {top: '0%'});
        gsap.set(reeseContainerRef.value, {left: reeseContainerX});
        gsap.set(voteStickyBtnRef.value, {width: stickyBtnWidth+'%'});
        gsap.set(voteCleanBtnRef.value, {width: cleanBtnWidth+'%'});

        // Add loading value to false for deeplink, make it a callback
        // loading.value = false;
        if( callback ) {
          callback();
        }
      }
    }

    /**
     * Check for deep link to see if users go directly to vote for Clean or Sticky.
     * 
     */
    const checkForDeeplink = () => {
      let propsId = '';
      if( props.id ) {
        propsId = props.id.toLowerCase();
        deeplinkId.value = propsId;
      }
      if( deeplinkId.value === 'sticky' ) {
        // correctDeeplink.value = true;
        // currentState.value = STATES.value.STICKY;
        // animateToStickyState(false);

        vote(1, false);
      } else if( deeplinkId.value === 'clean' ) {
        // correctDeeplink.value = true;
        // currentState.value = STATES.value.CLEAN;
        // animateToCleanState(false);

        vote(2, false);
      }
    }

    
    /**
     * Tap Filter
     */
    const onFilterBtnClicked = () => {
      console.log('onFilterBtnClicked');
      if( currentState.value === STATES.value.STICKY ) {
        console.log('url:', process.env.VUE_APP_SNAP_URL_STICKY);
        window.open(process.env.VUE_APP_SNAP_URL_STICKY);
      } else {
        console.log('url:', process.env.VUE_APP_SNAP_URL_CLEAN);
        window.open(process.env.VUE_APP_SNAP_URL_CLEAN);
      }
    }

    /**
     * Like Particles
     */
    
    let emitter = null;
    const initEmitter = (particleId, minX=0, maxX=0) => {
      let container = null;

      if( particleId === 'sticky' ) {
        container = voteStickyBtnParticleContainerRef.value;
      } else {
        container = voteCleanBtnParticleContainerRef.value;
      }

      emitter = new Emitter(container, particleId, minX, maxX);

      let delta = maxX - minX;
      console.log('delta:', delta);
      let particlesAmount = 20;
      // particlesAmount = Math.max(particlesAmount, Math.round(delta / 5));
      console.log('particlesAmount:', particlesAmount);
      emitter.createParticles(particlesAmount);

      window.emitter = emitter;

      emitter.start();
    }


    return {
      // votes,
      loading,
      storeVotes,
      currentState,
      canVote,
      STATES,
      cleanVotePer,
      stickyVotePer,
      
      // Methods
      getVoteBtnSize,
      onVoteStickyBtnClicked,
      onVoteCleanBtnClicked,
      onFilterBtnClicked,

      // Refs
      headerAfterVotingRef,
      headerVotingRef,
      bgYellowRef,
      reeseContainerRef,
      voteCleanBtnRef,
      voteStickyBtnRef,
      filterBtnRef,
      voteParticleCanvasRef,
      voteParticleContainerRef,
      voteBtnsContainerRef,
      voteCleanBtnParticleContainerRef,
      voteStickyBtnParticleContainerRef,
    };
  }
}
</script>

<style lang="scss">
.home {
  position: relative;
  font-size: 1em;
  height: 100%;
  overflow-y: hidden;
  margin: 0 auto;
  max-width: 600px;
  min-height: 400px;
}

.hv-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('~@/assets/bg_orange.jpg') no-repeat;
  background-size: cover;
}

.hv-bg-yellow {
  position: absolute;
  background: #ffee02;
  top: 30%;
  width: 100%;
  height: 100vh;

  &::after {
    content: '';
    display: block;
    position: absolute;
    // top: -3vh;
    top: -2%;
    left: 0;
    width: 100%;
    background: url('~@/assets/bg_yellow_top.png') repeat-x;
    background-size: cover;
    padding-top: 60 / 872 * 100%;
  }
}

.hv-header {
  position: relative;
  color: $hershey-brown-color;
  font-size: 6em;
  // font-size: 5.4em;
  line-height: 1em;
  text-align: left;
  font-weight: 800;
  // padding: 0.7em 0 0 0.7em;
}

.hv-header-line {
  position: absolute;
  top: 0.7em;
  left: 0.7em;
}

.hv-header-one {
  font-size: 0.9em;
  line-height: 1.05em;
}

.hv-header-two {
  font-size: 0.9em;
  line-height: 1.05em;
  color: $hershey-yellow-color;
}

.hv-header-after-voting {
  font-size: 0.8em;
  line-height: 1em;
}

.hv-bottom-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hv-reese-container {
  position: relative;
  width: 150%;
  left: -21%;
  display: flex;
  text-align: center;
}

.hv-reese-cup {
  position: relative;
  width: calc(92% / 3);
  background: url('~@/assets/reese_v2.png') no-repeat;
  background-size: cover;
  margin: 0 1% 0 1%;
  
  &::after {
    content: '';
    display: block;
    // padding-top: 487 / 499 * 100%;
    padding-top: 525 / 650 * 100%;
  }

  &.full {

    &::after {
      // padding-top: 487 / 499 * 100%;
    }
  }

  &.sticky {
    margin-left: 0;
    // height: calc(487 / 525 * 100%);
    // background-image: url('~@/assets/reese_sticky.png');
    background-image: url('~@/assets/reese_sticky_v2.png');

    // &::after {
    //   padding-top: 525 / 650 * 100%;
    // }
  }

  &.clean {
    margin-right: 0;
    // background-image: url('~@/assets/reese_clean.png');
    background-image: url('~@/assets/reese_clean_v2.png');
    // padding-top: 525 / 650 * 100%;
  }
}

.hv-vote-btns-container {
  position: relative;
  width: 84%;
  font-size: 2.75em;
  margin: 1.75em auto 0.75em auto;
  display: flex;
  max-width: 450px;
  z-index: 7;
}

.vote-btn {
  position: relative;
  font-size: 1em;
  // padding: 0em 0.9em;
  height: 3em;
  width: 50%;
  color: $hershey-yellow-color;
  // background-color: $hershey-brown-color;
  font-weight: 800;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent;
  

  &.disabled {
    cursor: default;
  }

  &.vote-sticky-btn {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;

    .vote-btn-bg {
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
    }
  }

  &.vote-clean-btn {
    
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

    .vote-btn-bg {
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      background-color: $hershey-red-color;
    }
  }

  &.large {
    .vote-btn-label, .vote-result {
      font-size: 0.9em;
    }
  }

  &.medium {
    .vote-btn-label, .vote-result {
      font-size: 0.8em;
    }
  }

  &.small {
    .vote-btn-label, .vote-result {
      font-size: 0.6em;
    }
  }

  &.xsmall {
    .vote-btn-label, .vote-result {
      font-size: 0.5em;
    }
  }
}

.vote-btn-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $hershey-brown-color;
}

.vote-result {
  position: relative;
  line-height: 0.9em;
}

.vote-btn-label {
  position: relative;
}

.vote-btn-particle-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  transform: translateX(-50%);

  canvas {
    width: 100%;
    height: 100%;
  }

  .vote-like-particle {
    position: absolute;
    top: 0;
    left: 0;
    background: url('~@/assets/icon_like_clean_shadow.png') no-repeat;
    background-size: cover;
    // background-color: #000;
    width: 1.25em;
    height: 1.25em;

    &.sticky {
      background-image: url('~@/assets/icon_like_sticky_shadow.png');
    }
  }
}

.hv-hashtag {
  position: relative;
  font-size: 2em;
  font-weight: 800;
  color: $hershey-brown-color;
  padding-bottom: 1.5em;
}

.hv-filter-btn {
  position: relative;
  color: $hershey-red-color;
  width: 80%;
  font-size: 2.2em;
  line-height: 1em;
  border: $hershey-red-color 0.1em solid;
  border-radius: 2em;
  background: $hershey-yellow-color;
  display: inline-block;
  margin: 0.5em auto 2em auto;
  padding: 0.65em 1.75em 0.6em 1.75em;
  font-weight: 800;
  // box-shadow: 7px 11px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0.4em 0.5em 0.2em rgba(0, 0, 0, 0.3);
  z-index: 5;
}


@include desktop-medium {

}

@include mobile {
  .hv-header {
    font-size: 5.5em;
    // font-size: 5em;
  }

  .hv-vote-btns-container {
    font-size: 2.75em;
  }

  .vote-btn {
    font-size: 1em;
  }

  .hv-hashtag {
    font-size: 2.25em;
  }
}

@include mobile-medium {
  .hv-header {
    font-size: 4.75em;
    // font-size: 4.275em;
    padding-top: 0.6em;
    padding-left: 0.65em;
  }

  .hv-header-line {
    top: 0.6em;
    left: 0.65em;
  }

  .hv-header-after-voting {
    font-size: 0.75em;
    line-height: 1em;
  }

  .hv-reese-container {
    
  }

  // .hv-reese-cup {
  //   width: calc(84% / 3);
  //   margin: 0 3% 0 3%;
  // }

  .hv-vote-btns-container {
    font-size: 2.5em;
    margin-top: 1.1em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 2.25em;
    padding-bottom: 1.35em;
  }

  .hv-filter-btn {
    font-size: 2.2em;
    margin-top: 0.3em;
    margin-bottom: 1.5em;
  }
}

@include mobile-small {
  .hv-header {
    font-size: 4.5em;
    // font-size: 4.0em;
    padding-top: 0.6em;
    padding-left: 0.65em;
  }

  .hv-header-line {
    top: 0.6em;
    left: 0.65em;
  }

  .hv-reese-cup {
    width: calc(94% / 3);
    margin: 0 0.5% 0 0.5%;
  }

  .hv-vote-btns-container {
    font-size: 2.1em;
    margin-top: 1.5em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 1.8em;
    padding-bottom: 1.4em;
  }

  .hv-filter-btn {
    font-size: 1.8em;
    width: 82%;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    
  }
}

@include height-medium {
  .hv-header {
    font-size: 4.75em;
    // font-size: 4.275em;
    padding-top: 0.6em;
    padding-left: 0.65em;
  }

  .hv-header-line {
    top: 0.6em;
    left: 0.65em;
  }

  .hv-header-after-voting {
    font-size: 0.75em;
    line-height: 1em;
  }

  .hv-reese-container {
    
  }

  // .hv-reese-cup {
  //   width: calc(84% / 3);
  //   margin: 0 3% 0 3%;
  // }

  .hv-vote-btns-container {
    font-size: 2.5em;
    margin-top: 1.1em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 2.25em;
    padding-bottom: 1.35em;
  }

  .hv-filter-btn {
    font-size: 2.2em;
    margin-top: 0.3em;
    margin-bottom: 1.5em;
  }
}

@include height-short {
  .hv-header {
    font-size: 4.0em;
    // font-size: 3.6em;
    padding-top: 0.6em;
    padding-left: 0.65em;
  }

  .hv-header-line {
    top: 0.6em;
    left: 0.65em;
  }

  .hv-reese-cup {
    width: calc(97% / 3);
    margin: 0 0.25% 0 0.25%;
  }

  .hv-vote-btns-container {
    font-size: 2.1em;
    margin-top: 1.25em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 1.75em;
    padding-bottom: 1.2em;
  }

  .hv-filter-btn {
    font-size: 1.8em;
    width: 82%;
    margin-top: 0.25em;
    margin-bottom: 1.25em;
    
  }
}

@include mobile-extra-small {
  .hv-header {
    font-size: 3.8em;
    // font-size: 3.4em;
    padding-top: 0.45em;
    padding-left: 0.5em;
  }

  .hv-header-line {
    top: 0.45em;
    left: 0.5em;
  }

  .hv-reese-cup {

  }

  .hv-vote-btns-container {
    font-size: 2.0em;
    margin-top: 1.25em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 1.5em;
    padding-bottom: 1.0em;
  }

  .hv-filter-btn {
    font-size: 1.75em;
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
}

@include mobile-extra-extra-small {
  .hv-header {
    font-size: 3.5em;
    // font-size: 3.15em;
    padding-top: 0.45em;
    padding-left: 0.5em;
  }

  .hv-header-line {
    top: 0.45em;
    left: 0.5em;
  }

  .hv-reese-cup {

  }

  .hv-vote-btns-container {
    font-size: 1.8em;
    margin-top: 1.0em;
  }

  .vote-btn {

  }

  .hv-hashtag {
    font-size: 1.3em;
    padding-bottom: 0.75em;
  }

  .hv-filter-btn {
    font-size: 1.5em;
    margin-top: 0.2em;
    margin-bottom: 1em;
  }
}

@include mobile-aspect-ratio-40-62 {

}

@include mobile-aspect-ratio-40-59 {

}

</style>
