
class Emitter {
  constructor(container, particleId='', minX=0, maxX=0) {
    this.container = container;
    this.minX = minX;
    this.maxX = maxX;
    this.startY = 0;

    this.frameRate = 100;
    this.timeElapsed = 0;

    this.rect = container.getBoundingClientRect();
    this.width = this.rect.width;
    this.height = this.rect.height;

    // console.log('minX:', minX);
    // console.log('maxX:', maxX);

    // console.log(this.rect);
    // console.log('container width:', this.width);
    // console.log('container height: ', this.height);

    this.startX = this.width / 2;
    this.startY = this.height;

    this.maxVelocity = 2;
    this.maxParticleSize = 7;

    this.particleId = 'clean';
    if( particleId != '' ) {
      this.particleId = particleId;
    }

    this.particles = [];
  }

  createParticles(amount) {
    this.particles = [];

    for( let i=0; i<amount; i++ ) {
      let left = this.startX + this.minX + (Math.random() * (this.maxX - this.minX));
      this.particles[i] = {
        'dom': document.createElement('div'),
        'opacity': 1,
        size: getRandomInt(1, 1.2),
        // 'left': this.startX + this.minX + getRandomInt(0, (this.maxX - this.minX)),
        'left': this.rect.width * 0.2 + (Math.random() * this.rect.width * 0.6),
        'top': this.startY - this.height*0.05,
        // 'x-velocity': getRandomInt(-this.maxVelocity * 10, this.maxVelocity * 10) / 100,
        'x-velocity': 0,
        'y-velocity': getRandomInt(-this.maxVelocity * 20, -this.maxVelocity * 80) / 100,
        'opacity-velocity': getRandomInt(-2, -5) / 1000,
      }

      // console.log('left:', this.particles[i]['left']);

      // console.log(this.minX + (Math.random() * (this.maxX - this.minX)));
      
      this.particles[i]['dom'].classList.add('vote-like-particle');
      this.particles[i]['dom'].classList.add(this.particleId);
      this.particles[i]['dom'].style.cssText = 
        'left: ' + this.particles[i]['left'] + 'px; ' +
        'top: ' + this.particles[i]['top'] + 'px;' + 
        'opacity: ' + this.particles[i]['opacity'];
      this.particles[i]['dom'].setAttribute('x-velocity', this.particles[i]['x-velocity']);
      this.particles[i]['dom'].setAttribute('y-velocity', this.particles[i]['y-velocity']);

      this.container.appendChild(this.particles[i]['dom']);
    }

    console.log(this.particles);
  }

  animateParticles() {
    // console.log('animateParticles');
    let end = true;

    for( let i=0; i<this.particles.length; i++ ) {
      let newX = (
        this.particles[i]['left'] 
        + (Math.random(1, 10) < 8 ? Math.random(this.particles[i]['x-velocity']) : -Math.random(this.particles[i]['x-velocity']))
      );
      // newX = 0;
      let newY = (this.particles[i]['top'] + this.particles[i]['y-velocity']);
      let newOpacity = (this.particles[i]['opacity'] + this.particles[i]['opacity-velocity']);

      // this.particles[i]['dom'].style.left = newX + 'px';
      this.particles[i]['dom'].style.top = newY + 'px';
      this.particles[i]['dom'].style.opacity = newOpacity;

      this.particles[i]['left'] = newX;
      this.particles[i]['top'] = newY;
      this.particles[i]['opacity'] = newOpacity;

      if( newOpacity > 0 ) {
        end = false;
      }
      
    }

    if( end ) {
      this.end();
    }
  }

  start() {
    if( this._timerId ) {
      clearInterval(this._timerId);
    }

    this._timerId = setInterval(() => {
      this.animateParticles();
      
    }, 1000 / this.frameRate);
  }

  end() {
    console.log('[emitter.js]', 'end()');
    clearInterval(this._timerId);
  }
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function Vector() {
  this.x = 0;
  this.y = 0;

  this.set = function(x, y) {}
}

export default Emitter;