import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue'
import router from './router'
import store from './store'

import { updateVh } from '@/lib/helpers';

import 'normalize.css';

Vue.config.productionTip = false

Vue.use(VueCompositionAPI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// updateVh();


import { gsap } from "gsap";

window.gsap = gsap;