import Vue from 'vue'
import Vuex from 'vuex'
import qs from 'qs';

Vue.use(Vuex)

const NAMESPACE = '[store/index.js]';

export default new Vuex.Store({
  state: {
    votes: {
      sticky: 1,
      clean: 1,
    },
    legalVisible: false,
  },
  mutations: {
    updateVotes(state, payload) {
      state.votes.sticky = payload.sticky;
      state.votes.clean = payload.clean;
    },
  },
  actions: {
    updateVotes({commit, state}, value) {
      commit('updateVotes', value);
    },

    getVotes({commit, state}, value) {
      console.log(NAMESPACE, 'getVotes');

      return new Promise((resolve, reject) => {
        let data = {
          action: 'get'
        };

        // let url = 'https://avatarclient.net/clients/snap/reese/stickyvsclean/';
        let url = process.env.VUE_APP_API_URL;

        fetch(url, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: qs.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            if( data.success === true ) {
              commit('updateVotes', {sticky: data.stickyVotes, clean: data.cleanVotes});
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            console.log(error);
            reject();
          })
      })
    },

    addVote({commit, state}, value) {

      console.log(NAMESPACE, 'addVote with id:', value);

      if( value.voteId == 1 ) {
        commit('updateVotes', {sticky: state.votes.sticky+1, clean: state.votes.clean});
      } else if( value.voteId == 2 ) {
        commit('updateVotes', {sticky: state.votes.sticky, clean: state.votes.clean+1});
      }
      

      return new Promise((resolve, reject) => {
        let data = {
          action: 'vote',
          voteId: value.voteId
        };

        // let url = 'https://avatarclient.net/clients/snap/reese/stickyvsclean/';
        let url = process.env.VUE_APP_API_URL;

        fetch(url, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: qs.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            if( data.success === true ) {
              commit('updateVotes', {sticky: data.stickyVotes, clean: data.cleanVotes});

              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            console.log(error);
            reject();
          })
      })
    }
  },
  modules: {
  }
})
